import { Injectable } from "@angular/core";
import { GrowthBook } from "@growthbook/growthbook";
import { AppService } from "./app.service";
import { FacilityModel } from "../models/facility";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from "rxjs";
import { ManagerModel } from "../models/manager";
import { L } from "@angular/cdk/keycodes";

@Injectable({ providedIn: 'root' })
export class GrowthbookService {

    private _gb: GrowthBook;
    private endpoint = environment.apiUrl;
    private growthbookClientKey = environment.growthbookClientKey; 
    private growthbookDecryptionKey = environment.growthbookDecryptionKey; 
    private autoConfirmSubject = new BehaviorSubject<GbAutoConfirmGroup>('Trusted_nurses'); // Default value

    autoConfirmFf$ = this.autoConfirmSubject.asObservable(); 

    constructor(
        private app: AppService,
        private http: HttpClient
    )
    {
        this.app.expose('services', 'growthbook', this);    
    }

    async onSetActiveFacility(facility: FacilityModel, user: ManagerModel)
    { 
        const extraData = { facilityId: facility._id };
        this._gb = new GrowthBook({
            apiHost: "https://cdn.growthbook.io",
            attributes: {
                "facility_id": facility._id,
                "loggedIn": true,
                "position": user.position,
                "user_id": user._id,
                "state": facility.address.state
            },
            clientKey: this.growthbookClientKey,
            decryptionKey: this.growthbookDecryptionKey,
            enableDevMode: true,
            trackingCallback: (experiment, result) => {
                this.http.post(`${this.endpoint}/growth-book/experiments`, { experimentId: experiment.key, variantId: result.key, facilityId: facility._id })
                    .subscribe();
            },
        });
        await this._gb.init({ streaming: true }); 
        this.getAutoConfirmGroup();

    }

    onLogout()
    {
        this._gb.destroy();
    }

    getAutoConfirmGroup(): GbAutoConfirmGroup
    {
        if (!this._gb || !this._gb.ready) return 'Trusted_nurses';
        let x =this._gb.getFeatureValue('auto-confirmation', 'Trusted_nurses') as any;
        this.autoConfirmSubject.next(x);
        return x;
    }

}

export type GbAutoConfirmGroup = 'Trusted_nurses' | 'All_nurses';