import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { cancelationRadioButtons } from '../modal-box/cancelation-radio-buttons';
import { Shift } from 'src/app/models/shift';
import { Applicant } from 'src/app/models/applicant';
import { ApplicantWithBindings } from '../../../bindings';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { Facility, FacilityModel } from 'src/app/models/facility';
import { NurseCancelationForm } from './nurse-cancelation-form';
import { BrazeService } from '../../../services/braze.service';

@Component({
  selector: 'app-cancel-nurse-modal',
  templateUrl: './cancel-nurse-modal.component.html',
  styleUrls: ['./cancel-nurse-modal.component.scss'],
})
export class CancelNurseModalComponent {

  @Input() facility: FacilityModel;
  @Input() lastMinute: boolean = false;
  @Input() nurseName: string;
  @Input() shift: Shift;
  @Input() applicant: ApplicantWithBindings;

  radioArr = cancelationRadioButtons;

  @Output() closeAction = new EventEmitter();
  @Output() modalAction = new EventEmitter();
  @Output() secondModalAction = new EventEmitter();
  @Output() modalActionWithCheckbox = new EventEmitter();
  @Output() modalCloseActionWithCheckbox = new EventEmitter();

  textSets: Record<string, { title: string; text: string; paragraph: string; checkboxLabel: string, submitText: string }> = {
    regular: { 
      title: 'Nurse cancelation', 
      text: '', 
      paragraph: 'Please confirm that you want to cancel this nurse and provide a reason below.', 
      checkboxLabel: '',
      submitText: 'Cancel the nurse' },
    lastMinute: { 
      title: 'Last minute cancelation', 
      text: '', 
      paragraph: 'Please confirm that you wish to proceed with this last-minute cancelation and provide a reason below.', 
      checkboxLabel: 'I understand that I will be billed for 2 hours for this last minute cancelation.',
      submitText: 'Confirm last minute cancelation' },
  };
  
  form: UntypedFormGroup = this.fb.group({ reason: new UntypedFormControl(), reasonNotes: new UntypedFormControl(), checkbox: new UntypedFormControl()});
  cancelationType: string;
  constructor(private fb: UntypedFormBuilder, private mixpanel: MixpanelService, private brazeService: BrazeService) {}

  ngOnInit() {
   this.cancelationType =  this.lastMinute ? 'lastMinute' : 'regular';
   const shiftDate = this.shift.shiftFrom.toFormat('LLLL d, y');
   this.form.get('reason').setValidators([Validators.required]);
   this.form.get('reasonNotes').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(280)]);
    
   const shiftTime =
     this.shift.shiftFrom.toFormat('h:mma').toLowerCase() +
     ' - ' +
     this.shift.shiftTo.toFormat('h:mma').toLowerCase();
   this.textSets['regular'].text = 'You’re canceling <b>' + this.applicant.user.name.first + '</b> from the shift on:<br><b>' + shiftDate + ', ' + shiftTime + ".</b>";
   this.textSets['lastMinute'].text = 'Canceling <b>' + this.applicant.user.name.first + '</b> within 2 hours of the shift start time will result in a <b>2-hour timecard being billed at the shift’s hourly rate.</b>'
  }

  onModalClose(event) {
    event.stopPropagation();
    this.closeAction.emit();
  }
  
  onOkClick(event){
    const cancellationForm : NurseCancelationForm = {
      reason: this.form.get('reason').value.toString(),
      reasonNotes: this.form.get('reasonNotes')?.value?.toString(), 
      isLateCancellationConfirmed: this.lastMinute
    }
    this.applicant.cancel(cancellationForm);

    this.brazeService.logCustomEvent('fm_slot_cancel', {
      shiftId: this.shift._id,
      shiftEndTime: this.shift.shiftTo.toString(),
      shiftStartTime: this.shift.shiftFrom.toString(),
      facilityId: this.facility._id,
      nurseId: this.applicant.model.user._id
    });

    if(this.lastMinute) {
      this.mixpanel.for('fm').track('LAST_MINUTE_CANCELLATION_MODAL_CANCEL', {facility: this.facility, shift: this.shift, applicant: this.applicant, nurseCancelationData: {reason: this.form.get('reason').value.toString(), reasonNotes: this.form.get('reasonNotes')?.value?.toString()}});
    }
    else {
      this.mixpanel.for('fm').track('CANCEL_NURSE', {facility: this.facility, shift: this.shift, applicant: this.applicant, nurseCancelationData: {reason: this.form.get('reason').value.toString(), reasonNotes: this.form.get('reasonNotes')?.value?.toString()}});
    }
    this.onModalClose(event);
  }

  onRadioClick(event: string, textRequired: boolean){
    this.form.get('reason').setValue(event);
    this.form.get('reasonNotes').reset();
    if(event === "Nurse can not work at our facility" || event === "Other") {
      this.form.get('reasonNotes').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(280)]);
    }
    else if(event === 'Filled by other agency') {
      this.form.get('reasonNotes').setValidators([Validators.minLength(2), Validators.maxLength(280)]);
    }
    else {
      this.form.get('reasonNotes').clearValidators();
    }
    this.form.get('reasonNotes').updateValueAndValidity();
  }

}
