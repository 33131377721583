
export const AUTO_CONFIRM_OPTIONS = [
  {
    value: 'trusted',
    valueName: 'Trusted nurses',
    text: 'Only nurses who you marked as Trusted will be auto-confirmed.'
  },
  {
    value: 'returning',
    valueName: 'Returning nurses',
    text: 'Only nurses who previously worked at your facility will be auto-confirmed.'
  },
  {
    value: 'all',
    valueName: 'All nurses',
    text: 'All ESHYFT nurses who apply to your shifts will be auto-confirmed.'
  },
  {
    value: 'none',
    valueName: 'None',
    text: 'All applied nurses need to be manually confirmed.'
  }
];

export const AUTO_CONFIRM_OPTIONS_OBJ = {
  trusted: 'Trusted nurses',
  returning: 'Returning nurses',
  all: 'All nurses',
  none: 'None',
}
export const AUTO_CONFIRM_OPTIONS_FOR_NURSE_OBJ = {
  trusted: 'trusted nurse',
  returning: 'returning nurse',
  all: 'all nurses',
}